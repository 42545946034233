import type {WebGLRenderTarget, MeshStandardMaterial, Texture, Vector2} from 'three'
import {Group, Vector3, Vector4, Mesh, ShaderMaterial, GLSL3, MathUtils, Color} from 'three'

import cat_data from './params/cat'
import duck_data from './params/duck'
import copilot_data from './params/copilot'

import vertexShader from './shaders/obj-vert'
import fragmentShader from './shaders/obj-frag'
import utilsGlsl from './shaders/utils-glsl'

import Timeline from './utils/timeline'
import type Assets from './assets'
import type Common from './common'
import type {MascotData} from './params/mascot-type'

interface MascotCommonUniforms {
  uResolution: {value: Vector2}
  uLightColor1: {value: Color}
  uLightColor2: {value: Color}
  uLightPos: {value: Vector3}
  uLightPos2: {value: Vector3}
  uProgress: {value: Vector3}
  uDiffuse_star: {value: Texture}
  uDiffuse_blue: {value: Texture}
  uScrollProgress: {value: Vector3}
}

interface CommonUniforms {
  uTime: {value: number}
  uBgColor1: {value: Color}
  uBgColor2: {value: Color}
}

export default class MainScene {
  group: Group = new Group()
  bgFbo: WebGLRenderTarget
  bgFbo_blue: WebGLRenderTarget
  groups: Group[] = []
  // _scrollProgress: Vector3 = new Vector3(0, 0, 0)
  // scrollProgress: Vector3 = new Vector3(0, 0, 0)
  commonUniforms: CommonUniforms = {
    uTime: {value: 0},
    uBgColor1: {
      value: new Color(0x000240),
    },
    uBgColor2: {
      value: new Color(0x0d1117),
    },
    // uScrollProgress: {
    //   value: this.scrollProgress
    // }
  }
  common: Common
  assets: Assets
  constructor(common: Common, assets: Assets, bgFbo: WebGLRenderTarget, bgFbo_blue: WebGLRenderTarget) {
    this.common = common
    this.assets = assets
    this.bgFbo = bgFbo
    this.bgFbo_blue = bgFbo_blue
    this.group.position.set(0, 0, 0)
  }

  init(): void {
    if (this.assets.gltfs.cat && this.assets.gltfs.cat.scene) {
      const tl = new Timeline({delay: 0})
      const {group4, group5, mascotCommonUniforms} = this.createNewGroup(
        this.assets.gltfs.cat.scene,
        cat_data,
        tl,
        'cat',
      )
      group5.userData.offsetScale = 1.0
      group5.userData.offsetRotateSpeed = 1
      group5.userData.scrollDistY = 2
      group5.userData.easeScale = 6

      group4.position.set(cat_data.group_data.position.x, -2, 0)

      group4.rotation.set(0, Math.PI * 1.5, 1)
      tl.to([group4.rotation], 3000, {
        y: 0,
        z: 0,
        easing: 'easeOutExpo',
      })
        .to(
          [group4.position],
          4000,
          {
            x: cat_data.group_data.position.x,
            y: cat_data.group_data.position.y,
            z: cat_data.group_data.position.z,
            easing: 'easeOutExpo',
          },
          0,
        )
        .to(
          [mascotCommonUniforms.uProgress.value],
          500,
          {
            x: 1,
          },
          0,
        )
      tl.start()
    }

    if (this.assets.gltfs.copilot && this.assets.gltfs.copilot.scene) {
      const tl = new Timeline({delay: 600})
      const {group4, group5, mascotCommonUniforms} = this.createNewGroup(
        this.assets.gltfs.copilot.scene,
        copilot_data,
        tl,
        'copilot',
      )
      group4.position.set(-1, -2.5, 0)
      group5.userData.offsetScale = 2.8
      group5.userData.offsetRotateSpeed = -1
      group5.userData.scrollDistY = 0
      group5.userData.easeScale = 4

      group4.rotation.set(0, -Math.PI * 1.5, -1)
      tl.to([group4.rotation], 2400, {
        y: 0,
        z: 0,
        easing: 'easeOutExpo',
      })
        .to(
          [group4.position],
          4400,
          {
            x: copilot_data.group_data.position.x,
            y: copilot_data.group_data.position.y,
            z: copilot_data.group_data.position.z,
            easing: 'easeOutExpo',
          },
          0,
        )
        .to(
          [mascotCommonUniforms.uProgress.value],
          500,
          {
            x: 1,
          },
          0,
        )

      tl.start()
    }

    if (this.assets.gltfs.duck && this.assets.gltfs.duck.scene) {
      const tl = new Timeline({delay: 1000})
      const {group4, group5, mascotCommonUniforms} = this.createNewGroup(
        this.assets.gltfs.duck.scene,
        duck_data,
        tl,
        'duck',
      )
      group4.position.set(1, -2, 0)
      group5.userData.offsetScale = 3
      group5.userData.offsetRotateSpeed = 1
      group5.userData.scrollDistY = 0
      group5.userData.easeScale = 3

      group4.rotation.set(0, Math.PI * 1.5, -1)
      tl.to([group4.rotation], 2000, {
        y: 0,
        z: 0,
        easing: 'easeOutExpo',
      })
        .to(
          [group4.position],
          3000,
          {
            x: duck_data.group_data.position.x,
            y: duck_data.group_data.position.y,
            z: duck_data.group_data.position.z,
            easing: 'easeOutExpo',
            onComplete: () => {
              this.common.isFinishedIntroAnim = true
            },
          },
          0,
        )
        .to(
          [mascotCommonUniforms.uProgress.value],
          500,
          {
            x: 1,
            onComplete: () => {
              if (this.common.startCopyAnimation) {
                this.common.startCopyAnimation()
              }
            },
          },
          0,
        )

      tl.start()
    }
  }

  createNewGroup(_group: Group, mascot_data: MascotData, tl: Timeline, name: string) {
    const group5 = new Group()

    group5.userData.position = new Vector3()
    group5.userData.rotation = new Vector3()
    group5.userData.scale = new Vector3(1, 1, 1)
    group5.userData.scrollDistY = -1
    group5.userData.uScrollProgress = new Vector3(0, 0, 0)

    group5.name = 'group5'

    this.groups.push(group5)
    this.group.add(group5)

    const group4 = new Group()
    group4.position.copy(mascot_data.group_data.position)
    group4.name = 'group4'
    group5.add(group4)

    const group3 = new Group()

    group3.scale.copy(mascot_data.group_data.scale)
    group3.rotation.copy(mascot_data.group_data.rotation)
    group3.rotation.order = mascot_data.group_data.order
    group3.userData.random = new Vector4(Math.random(), Math.random(), Math.random(), Math.random())
    group4.add(group3)
    group3.name = 'group3'

    const group2 = new Group()
    group3.add(group2)
    group2.name = 'group2'

    const group1 = new Group()
    group2.add(group1)
    group1.name = 'group1'

    const mascotCommonUniforms: MascotCommonUniforms = {
      uResolution: {value: this.common.fbo_screenSize},
      uLightColor1: {value: mascot_data.light_data.color1},
      uLightColor2: {value: mascot_data.light_data.color2},
      uLightPos: {value: mascot_data.light_data.position},
      uLightPos2: {value: mascot_data.light_data.position2},
      uProgress: {value: new Vector3(0, 0, 0)},
      uDiffuse_star: {
        value: this.bgFbo.texture,
      },
      uDiffuse_blue: {
        value: this.bgFbo_blue.texture,
      },
      uScrollProgress: {
        value: group5.userData.uScrollProgress,
      },
    }

    _group.traverse(child => {
      if (child instanceof Mesh) {
        this.createNewMesh(child, group1, mascot_data, name, mascotCommonUniforms)
      }
    })

    return {group4, group5, mascotCommonUniforms}
  }

  createNewMesh(
    child: Mesh,
    group: Group,
    mascot_data: MascotData,
    name: string,
    mascotCommonUniforms: MascotCommonUniforms,
  ) {
    const geometry = child.geometry
    const _material = child.material as MeshStandardMaterial

    const texData = mascot_data.textures[child.name as keyof typeof mascot_data.textures]

    if (!texData) return

    const ao_name = texData.ao
    const color_name = texData.color
    const colorVec = texData.colorVec ? texData.colorVec : _material.color
    const matcap = texData.matcap

    const ao = ao_name && this.assets.images[ao_name] ? this.assets.images[ao_name].texture : null
    const colorTex = color_name && this.assets.images[color_name] ? this.assets.images[color_name].texture : null

    const matcapTex = matcap && this.assets.images[matcap] ? this.assets.images[matcap].texture : null

    const uniforms = {
      uAo: {value: ao},
      uColor: {value: colorVec},
      uColorTex: {value: colorTex},
      uMatcapTex: {value: matcapTex},
      uNoiseRange: {value: texData.noiseRange},
      uFogRangeZ: {value: texData.fogRangeZ},
      uTranslate: {value: child.position},
      ...mascotCommonUniforms,
      ...this.commonUniforms,
    }

    const material = new ShaderMaterial({
      vertexShader,
      fragmentShader: utilsGlsl + fragmentShader,
      uniforms,
      transparent: true,
      defines: {
        USE_COLORTEX: !!colorTex,
        MASCOT_TYPE: name === 'cat' ? 0 : name === 'copilot' ? 1 : 2,
      },
      glslVersion: GLSL3,
    })

    const mesh = new Mesh(geometry, material)
    mesh.position.copy(child.position)
    group.add(mesh)
  }

  scroll(): void {}

  update({
    duckProgress,
    catProgress,
    copilotProgress,
    bgProgress,
  }: {
    duckProgress: number
    catProgress: number
    copilotProgress: number
    bgProgress: number
  }): void {
    this.commonUniforms.uTime.value += this.common.delta

    for (let i = 0; i < this.groups.length; i++) {
      const group5 = this.groups[i]
      if (!group5) return
      const uScrollProgress = group5.userData.uScrollProgress
      switch (i) {
        case 0:
          uScrollProgress.x = catProgress
          break
        case 1:
          uScrollProgress.x = copilotProgress
          break
        case 2:
          uScrollProgress.x = duckProgress
          break
      }
      uScrollProgress.y = bgProgress

      const scale = MathUtils.lerp(1.0, 0.95, uScrollProgress.x)
      group5.scale.set(scale, scale, scale)
    }
  }
}
