export default `
uniform vec3 uColor1;
uniform vec3 uColor2;
uniform vec3 uBgColor1;
uniform vec3 uBgColor2;
uniform float uTime;

uniform vec2 uResolution;
uniform vec4 uProgress;
uniform vec3 uScrollProgress;

varying vec2 vUv;

const float PI = 3.14159265359;

float pointToLineDistance(float a, float b, float x1, float y1) {
    return abs(a * x1 - y1 + b) / sqrt(a * a + 1.0);
}

float fbm(float x, float y, float t) {
  float amplitude = 1.;
  float frequency = 1.;
  y = sin(x * frequency);
  y += sin(x*frequency*2.1 + t) * 4.5;
  y += sin(x*frequency*1.72 + t * 1.121) * 4.0;
  y += sin(x*frequency*2.221 + t * 0.437) * 5.0;
  y += sin(x*frequency*3.1122+ t * 4.269) * 2.5;
  y *= amplitude* 0.06;
  return y;
}

void main(){
  vec2 st = gl_FragCoord.xy / uResolution;
  st -= 0.5;
  st *= uResolution / uResolution.y;
  st *= 1.0 + uScrollProgress.x * 0.2;
  st *= 0.8;
  // st += 0.5;
  st.y += 0.47;

  float centerRadiantRadius = length((st + vec2(0.0, 0.0)));
  float bottomLightRadius = length(st + vec2(0.0, 4.0));

  float angle = atan(st.y, st.x);
  float radius = length(st);
  radius *= mix(1.1, 1.15, fbm(angle * 4.0, angle * 2.0,  uProgress.z * 4.0) * 0.5 + 0.5);


  float maxRadius = mix(1.0, 1.4, fbm(angle * 2.0, angle * 2.0,  uProgress.z * 4.0) * 0.5 + 0.5) - (1.0 - uProgress.y);

  float radiantAngle = 0.26;
  float radiantOffset = 0.05;

  float a = 1.0;
  float b = 0.2;
  float blurRange = 0.2;

  float d_right = pointToLineDistance(a, b, st.x, st.y);
  float light_right = smoothstep(blurRange, 0.0, d_right);
  light_right = mix(1.0, light_right, step(st.y, st.x * a + b));
  float d_left = pointToLineDistance(-a, b, st.x, st.y);
  float light_left = smoothstep(blurRange, 0.0, d_left);
  light_left = mix(1.0, light_left, step(st.y, -st.x * a + b));

  float light = light_right * light_left;
  light = mix(light, 0.0, smoothstep(0.0, maxRadius, radius));

  float gradientFactor = mix(
    mix(0.0, 0.4, 1.0 - vUv.y),
    mix(0.8, 1.17, 1.0 - vUv.y),
    uScrollProgress.y
  );
  vec3 bg = mix(uBgColor1, uBgColor2, gradientFactor);

  vec3 color = mix(uColor1, uColor2, light * light * 1.3);
  color *= mix(1.7, 1.0, smoothstep(0.1, 0.4, radius));
  color += mix(0.2, 0.0, smoothstep(0.1, 0.4, radius));


  float alpha = min(1.0, light * uProgress.x * 1.3);
  alpha = mix(alpha, alpha * 0.8, uScrollProgress.x);
  alpha = mix(0.0, alpha, smoothstep(0.0, 0.1, vUv.x));
  alpha = mix(0.0, alpha, smoothstep(1.0, 0.9, vUv.x));
  // float arch = 1.0 - pow(vUv.x - 0.5, 2.0);
  // alpha = mix(0.0, alpha, smoothstep(arch, arch - 0.3, vUv.y));

  color = mix(bg, color, alpha);


  gl_FragColor = vec4(color, alpha);
}
`
