import MainScene from './main-scene'
import Background from './background'
import type Assets from './assets'
import type Common from './common'
import {MathUtils} from 'three'

export default class Artwork {
  private $wrapper: HTMLElement
  private mainScene?: MainScene
  private background: Background
  private assets: Assets
  private common: Common
  public catProgress: {current: number; target: number} = {
    current: 0,
    target: 0,
  }
  public copilotProgress: {current: number; target: number} = {
    current: 0,
    target: 0,
  }
  public duckProgress: {current: number; target: number} = {
    current: 0,
    target: 0,
  }
  public copyProgress: {current: number; target: number} = {
    current: 0,
    target: 0,
  }
  public isRendering: boolean = true

  constructor($wrapper: HTMLElement, $canvas: HTMLCanvasElement, common: Common, assets: Assets) {
    this.$wrapper = $wrapper
    this.common = common
    this.assets = assets
    this.common.init({
      $wrapper: this.$wrapper,
      $canvas,
    })

    this.background = new Background(this.common, this.assets)
    this.mainScene = new MainScene(this.common, this.assets, this.background.fbo, this.background.fbo_blue)

    this.common.camera.position.set(0, 0.0, 2.2)
    this.common.camera.lookAt(this.common.scene.position)

    this.common.scene.add(this.mainScene.group)
  }

  init(): void {
    this.background?.init()
    this.mainScene?.init()
    this.common.scene.add(this.background.outputPlane)
    this.resize()
  }

  resize(): void {
    this.common.resize()
    this.background?.resize()
  }

  scroll(): void {
    const scale = (this.common.cameraTop * 2) / this.common.screenSize.y
    let scrollValue = window.scrollY * scale

    const duckProgress = MathUtils.smoothstep(scrollValue, 0, 1.0)
    const copilotProgress = MathUtils.smoothstep(scrollValue, 0.2, 1.2)
    const copyProgress = MathUtils.smoothstep(scrollValue, 0.6, 3)
    const catProgress = MathUtils.smoothstep(scrollValue, 1.5, 3.5)

    this.duckProgress.target = duckProgress
    this.copilotProgress.target = copilotProgress
    this.catProgress.target = catProgress
    this.copyProgress.target = copyProgress

    this.isRendering = catProgress !== 1

    scrollValue = Math.min(4.0, scrollValue)

    this.background?.scroll(scrollValue)
  }

  updateProgress(progress: {current: number; target: number}, easeScale: number) {
    if (progress.current === progress.target) {
      return
    }
    progress.current += (progress.target - progress.current) * easeScale
    if (Math.abs(progress.current - progress.target) < 0.001) {
      progress.current = progress.target
    }
  }

  update(): void {
    this.common.update()
    const easeScale = this.common.getEase(this.common.delta * 300.0)
    this.updateProgress(this.duckProgress, easeScale)
    this.updateProgress(this.copilotProgress, easeScale)
    this.updateProgress(this.catProgress, easeScale)
    this.updateProgress(this.copyProgress, easeScale)

    if (
      this.duckProgress.current === 0 &&
      this.copilotProgress.current === 0 &&
      this.catProgress.current === 0 &&
      this.copyProgress.current === 0
    ) {
      this.isRendering = false
    }

    if (this.isRendering || !this.common.isFinishedIntroAnim) {
      this.background?.update()
      this.mainScene?.update({
        bgProgress: this.background.uScrollProgress.y,
        duckProgress: this.duckProgress.current,
        copilotProgress: this.copilotProgress.current,
        catProgress: this.catProgress.current,
      })

      this.common.renderer?.setRenderTarget(null)
      this.common.renderer?.render(this.common.scene, this.common.camera)
    }
  }
}
